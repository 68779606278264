export const AdminRoute = {
  Home: 'admin',
  AnalyzedQuestion: 'admin/analyzedQuestion',
  AnalyzedQuestionId: 'admin/analyzedQuestion/:questionId',
  // questions
  Questions: 'admin/questions',
  Media: 'admin/questions/media',
  Question: 'admin/questions/question',
  QuestionId: 'admin/questions/question/:id',
  // series
  Series: 'admin/series',
  SeriesGenerate: 'admin/series/generate',
  SeriesArchives: 'admin/series/archives',
  SeriesArchivesLogsUsers: 'admin/series/archives/logs',
  SeriesArchivesDownloadedSeries: 'admin/series/archives/series',
  SeriesArchivesDownloadedSeriesId: 'admin/series/archives/series/:id',
  // sessions
  Session: 'admin/sessions/session',
  SessionId: 'admin/sessions/session/:id',
  Sessions: 'admin/sessions',
  // settings
  Settings: 'admin/settings',
  Users: 'admin/settings/users',
  Business: 'admin/settings',
  // settings Business
  Categories: 'admin/settings/business/categories',
  CategoryId: 'admin/settings/business/categories/:id',
  SeriesId: 'admin/settings/business/categories/:id/series',
  Tags: 'admin/settings/business/tags',
  Themes: 'admin/settings/business/themes',
  TrainingQuestions: 'admin/settings/business/training-questions',

  // statistics
  Statistics: 'admin/statistics',
  StatsCategories: 'admin/statistics/categories',
  StatsThemes: 'admin/statistics/themes',
  StatsQuestions: 'admin/statistics/questions',
  Archives: 'admin/archives',
};

export const RouteLabel: Record<keyof typeof AdminRoute, string> = {
  Home: 'Tableau de bord',
  AnalyzedQuestion: 'Analyser la question',
  AnalyzedQuestionId: 'Analyser la question',
  Media: 'Banque de médias',
  Themes: 'Les thèmes',
  Question: 'Créer une question',
  QuestionId: 'Question :id',
  Questions: 'Banque de questions',
  Series: 'Série',
  SeriesGenerate: 'Générer une série',
  SeriesArchives: 'Archives',
  SeriesArchivesLogsUsers: 'Logs Utilisateurs',
  SeriesArchivesDownloadedSeries: 'Séries téléchargées',
  SeriesArchivesDownloadedSeriesId: 'Série téléchargée',
  SeriesId: 'Série :id',
  Session: 'Créer une session',
  SessionId: "Session d'examen :id",
  Sessions: "Session d'examen",
  Settings: 'Paramètres',
  Users: 'Gestion des accès',
  Business: 'Gestion des examens',
  Categories: 'Catégories',
  CategoryId: ':name',
  Tags: 'Etiquettes',
  Statistics: 'Statistiques',
  StatsQuestions: "Question d'examen",
  StatsThemes: 'Les thèmes',
  TrainingQuestions: "Les questions d'entraînement",
  StatsCategories: 'Catégories',
  Archives: 'Archives',
};

export const getAnalyzedQuestionIdRoute = (questionId: string): string =>
  `/${AdminRoute.AnalyzedQuestion}/${questionId}`;

export const getQuestionIdRoute = (questionId: string): string =>
  `/${AdminRoute.Question}/${questionId}`;

export const getSeriesArchivesDownloadedSeries = (seriesId: string): string =>
  `/${AdminRoute.SeriesArchivesDownloadedSeries}/${seriesId}`;

export const getCategorySeriesIdRoute = (categoryId: string): string =>
  `/${AdminRoute.Categories}/${categoryId}/series`;

export const getSessionIdRoute = (sessionId: string): string =>
  `/${AdminRoute.Session}/${sessionId}`;

export const getCategoryIdRoute = (categoryId: string): string =>
  `/${AdminRoute.Categories}/${categoryId}`;

export const CandidateRoute = {
  Home: 'candidate',
  Login: 'candidate/login',
};
